import React from 'react';
import styled from 'styled-components';
import arrow from 'images/arrow-bottom.svg';

export type AccordionProps = {
  header: string;
};

export const Header = styled.summary`
  background-color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  padding: 1rem 2.5rem 1rem 0.625rem;
  position: relative;
  text-align: left;
  cursor: pointer;
  &::-webkit-details-marker {
    display: none;
  }
`;

export const ArrowIcon = styled.div`
  background: url(${arrow}) no-repeat;
  width: 1.25rem;
  height: 0.6875rem;
  flex-shrink: 0;
  margin-left: 0.625rem;
  position: absolute;
  top: calc(50% - 0.3437rem);
  right: 1.25rem;
`;

export const Content = styled.div`
  p {
    margin-top: 0.625rem;

    @media (max-width: 768px) {
      margin-top: 0.3125rem;
    }
  }
`;

export const Container = styled.details`
  font-size: 0.9375rem;
  line-height: 1.0625rem;
  text-align: left;

  
  ${ArrowIcon} {
    transition: all 0.3s ease;
  }
  &[open] {
    ${ArrowIcon} {
      transform: rotateX(180deg);
    }
  }
`;

export const Accordion: React.FC<
  AccordionProps & React.DetailsHTMLAttributes<HTMLDetailsElement>
> = ({header, children, ...props}) => {
  return (
    <Container {...props}>
      <Header>
        {header}
        <ArrowIcon></ArrowIcon>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};
