import styled from "styled-components";
import { Accordion as DefaultAccordion } from "./Accordion";
import React from "react";
import { containerSize, ScreenSizes, Sx } from "../../types/responsive";
import { SectionHeader } from "./Section";

const FaqSection = styled.section`
  padding: 0.5rem 30px 0.625rem;
  width: 100%;
  max-width: ${containerSize};
  margin: auto;
  p {
    line-height: 150%;
  }
  @media (max-width: ${ScreenSizes.md}) {
    padding: 0.5rem 0 0.625rem;
  }
`;

const Accordion = styled(DefaultAccordion)`
  margin-bottom: 0.625rem;
`;

const FaqTitle = styled(SectionHeader)`
  padding: 0 40px;
  margin: auto;
  text-align: center;
  @media (max-width: ${ScreenSizes.md}) {
    padding: 0;
    width: 100%;
  }
`
type Faq = {
  header: string;
  content?: JSX.Element|string;
  text?: string;
}
export const FaqsSection: React.FC<{title: string|JSX.Element; faqs: Faq[], sx?: Sx}> = ({faqs, title}) => {
  return (
    <>
      <FaqTitle underline>
        {title}
      </FaqTitle>
      <FaqSection className="faqs-section">
        {faqs.map((faq, index) => (
          <Accordion key={index} header={faq.header}>
            <p>{faq.content}</p>
          </Accordion>
        ))}
      </FaqSection>
    </>

  )
}
