// i18next-extract-mark-ns-start blog

import {TFunction} from 'i18next';
import {BlogPost} from '../../../types/BlogTypes';
import moment from 'moment';

export const getBlogPostSchema: (t: TFunction, post: BlogPost) => any = (t, post) => {
  const schema: any = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': 'https://monei.com/' + post.lang + post.slug
    },
    headline: post.title[0].text,
    image: post.imageUrl,
    author: {
      '@type': 'Person',
      name: 'Alexis Damen',
      url: 'https://www.linkedin.com/in/alexis-mera-damen-0732ab13/'
    },
    publisher: {
      '@type': 'Organization',
      name: 'MONEI',
      logo: {
        '@type': 'ImageObject',
        url: 'https://assets.monei.com/images/logo-square.png'
      }
    },
    datePublished: moment.utc(post.firstPublicationDate!)
  };

  if (post.faqs && post.faqs.length) {
    schema['mainEntity'] = post.faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.title,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text.map((text) => text.text).join(', ')
      }
    }));
  }

  return schema;
};
