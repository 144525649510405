// i18next-extract-mark-ns-start blog

import {BlogTitleH1} from 'components/blog/BlogComponents';
import {SEO} from 'components/SEO';
import {graphql, PageProps} from 'gatsby';
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import {colors} from 'global.config';
import BlogLayout from 'layouts/BlogLayout';
import {RichText} from 'prismic-reactjs';
import React from 'react';
import styled from 'styled-components';
import {htmlSerializer} from 'utils/htmlSerializer';
import {BlogCategoryType, BlogLocale, BlogPost} from '../../types/BlogTypes';
import {getBlogPostFromEdge, getPostsFromPrismicEdges, mapCategory} from 'utils/blog-utils';
import {ScreenSizes} from '../../types/responsive';
import {getBlogPostSchema} from 'components/blog/customSchemas';
import {setMomentLocale} from 'utils/helpers';
import {FaqsSection} from 'components/FaqsSection';
import {Section} from 'components/Section';

type Context = {
  prismicLang: any;
  paginationNextUid: string | null;
  paginationPreviousUid?: string | null;
};

const Article = styled.article`
  flex: 1;
`;

const Info = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #acb6bb;
  margin: 0 0 2rem;
`;

const FeaturedImage = styled.div`
  margin-bottom: 1.64rem;
`;

const Image = styled.div`
  position: relative;
  display: block;
  background: #f3f4f6;

  img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin-bottom: 0;
    border-radius: 0.5rem;
  }
`;

const ImageSource = styled.div`
  color: ${colors.grey};
  font-size: 0.9rem;
  margin-top: 0.5rem;

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const Category = styled(Link)`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #aab4e9;
  margin: 0 0 0.5rem;
`;

const PostTags = styled.div``;

const BlogAuthor = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 0 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 2rem 2rem 2rem 0;
  img {
    grid-row-start: 1;
    grid-row-end: 3;
    border: 2px solid transparent;
    margin: 0;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(-135deg, rgb(172, 182, 229) 0%, rgb(116, 235, 213) 100%);
  }
  h3 {
    margin: 0;
  }
  p {
    margin: 0;
  }
  @media (max-width: ${ScreenSizes.xs}) {
    grid-template-columns: 15% 85%;
    gap: 1rem;
    img {
      grid-row-start: 1;
      grid-row-end: 2;
    }
    h3 {
      align-self: center;
      grid-row-start: 1;
      grid-row-end: 2;
      grid-column-start: 2;
      grid-column-end: 3;
    }
    p {
      grid-row-start: 2;
      grid-row-end: 4;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
`;

const filterOutEmbeds = (body: any[]): any[] => {
  if (!Array.isArray(body)) return [];
  return body.filter(item => !(item.type === 'embed' && (!item.oembed || item.oembed.embed_url === '')));
};

const BlogPostPage: React.FC<PageProps<any, Context> & {prismic: any}> = ({data, uri, path}) => {
  const {t, language} = useI18next();
  setMomentLocale(language as BlogLocale);

  const categories: BlogCategoryType[] = data.prismic.allCategorys.edges.map(mapCategory) || [];
  const popularPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.popularPosts.allPosts.edges,
    categories
  });
  const mustReadPosts: BlogPost[] = getPostsFromPrismicEdges({
    posts: data.mustReadPosts.allPosts.edges,
    categories
  });

  if (!data.prismic.post) return null;
  const post = getBlogPostFromEdge(data.prismic.post, categories);
  const schema = getBlogPostSchema(t, post as BlogPost);
  
  // Filter out embeds from post.body and reassign
  post.body = filterOutEmbeds(post.body || []);

  return (
    <>
      <SEO
        path={path}
        title={post.meta_title}
        description={post.meta_description}
        bodyAttributes={{class: 'blog'}}
        preview={post.imageUrl}
        schema={schema}
      />
      <BlogLayout
        categories={categories}
        activeCategory={post.category?.slug}
        popularPosts={popularPosts}
        mustReadPosts={mustReadPosts}
        post={post}
        uri={uri}>
        <Article>
          {post.category && (
            <Category to={`/blog/${post.category.slug}`}>{post.category.title}</Category>
          )}
          {post.title && (
            <BlogTitleH1>
              <RichText render={post.title} />
            </BlogTitleH1>
          )}
          <Info>
            {post.author_card?.author_name && (
              <Link to={`/author/${post.author_card._meta.uid}`}>
                {RichText.asText(post.author_card.author_name)}
              </Link>
            )}
            {post.author_card && ` | `}
            {post.firstPublicationDate} {post.updated_at && `| ${t('Updated')}: ${post.updated_at}`}
          </Info>
          {post.imageUrl && (
            <FeaturedImage>
              <Image style={{paddingTop: `${post.imageRatio}%`}}>
                <img src={post.imageUrl} alt={RichText.asText(post.title)} />
              </Image>
              {post.image_source && (
                <ImageSource>
                  <RichText render={post.image_source} />
                </ImageSource>
              )}
            </FeaturedImage>
          )}
          <RichText render={post.body} htmlSerializer={htmlSerializer} />

          {post.custom_html ? (
            <div dangerouslySetInnerHTML={{__html: post.custom_html[0].text}} />
          ) : (
            <></>
          )}

          {Boolean(post.faqs?.length) ? (
            <Section centered column>
              <FaqsSection
                title={post.faqsTitle}
                faqs={
                  post.faqs.map((faq) => ({
                    header: faq.title,
                    content: <RichText render={faq.text} htmlSerializer={htmlSerializer} />
                  })) || []
                }
              />
            </Section>
          ) : (
            <></>
          )}
          {post.author_card && (
            <BlogAuthor>
              <img
                src={post.author_card.author_image.url}
                alt={post.author_card.author_name.text}
              />
              <RichText render={post.author_card.author_name} />
              <RichText render={post.author_card.author_description} />
            </BlogAuthor>
          )}

          <PostTags>
            {post.tags
              .filter((tag) => !['Popular', 'Must Read'].includes(tag))
              .map((tag) => (
                <Link to={`/blog/?tags=${tag}`} key={tag} style={{fontSize: '0.9rem'}}>
                  #{tag}
                  {'  '}
                </Link>
              ))}
          </PostTags>
        </Article>
      </BlogLayout>
    </>
  );
};

export default BlogPostPage;

export const query = graphql`
  query BlogPostPage(
    $uid: String!
    $prismicLang: String!
    $language: String!
    $paginationPreviousUid: String = ""
    $paginationNextUid: String = ""
  ) {
    locales: allLocale(filter: {ns: {in: ["common", "blog"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    prismic: prismic {
      allCategorys(lang: $prismicLang) {
        edges {
          node {
            title
            _meta {
              uid
              lang
            }
            order
          }
        }
      }
    }

    prismic: prismic {
      post(uid: $uid, lang: $prismicLang) {
        title
        meta_title
        body
        cta {
          ... on PRISMIC_Call_to_action {
            image
            button_text
            link {
              ... on PRISMIC__ExternalLink {
                target
                url
              }
            }
            top
            left
          }
        }
        summary
        image
        image_source
        custom_html
        meta_description
        category
        updated_at
        author_card {
          ... on PRISMIC_Author {
            author_name
            author_description
            author_image
            _meta {
              uid
            }
          }
        }
        _meta {
          uid
          lang
          alternateLanguages {
            lang
          }
          firstPublicationDate
          tags
        }
        slice_zone {
          ... on PRISMIC_PostSlice_zoneFaq_section {
            fields {
              title
              text
            }
            type
            primary {
              faqs_description
              faqs_title
            }
          }
        }
      }
    }
    prismic: prismic {
      prevArticle: post(uid: $paginationPreviousUid, lang: $prismicLang) {
        title
        _meta {
          uid
          lang
          type
        }
      }
      nextArticle: post(uid: $paginationNextUid, lang: $prismicLang) {
        title
        _meta {
          uid
          lang
          type
        }
      }
    }
    popularPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Popular"
        first: 3
        lang: $prismicLang
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
    mustReadPosts: prismic {
      allPosts(
        sortBy: meta_lastPublicationDate_DESC
        tags: "Must Read"
        first: 3
        lang: $prismicLang
      ) {
        edges {
          node {
            title
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`;
